exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-brand-tsx": () => import("./../../../src/pages/brand.tsx" /* webpackChunkName: "component---src-pages-brand-tsx" */),
  "component---src-pages-contributors-tsx": () => import("./../../../src/pages/contributors.tsx" /* webpackChunkName: "component---src-pages-contributors-tsx" */),
  "component---src-pages-explore-tsx": () => import("./../../../src/pages/explore.tsx" /* webpackChunkName: "component---src-pages-explore-tsx" */),
  "component---src-pages-fund-management-tsx": () => import("./../../../src/pages/fund-management.tsx" /* webpackChunkName: "component---src-pages-fund-management-tsx" */),
  "component---src-pages-governance-tsx": () => import("./../../../src/pages/governance.tsx" /* webpackChunkName: "component---src-pages-governance-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-prime-tsx": () => import("./../../../src/pages/prime.tsx" /* webpackChunkName: "component---src-pages-prime-tsx" */),
  "component---src-pages-rwa-market-tsx": () => import("./../../../src/pages/rwa-market.tsx" /* webpackChunkName: "component---src-pages-rwa-market-tsx" */),
  "component---src-templates-legal-tsx": () => import("./../../../src/templates/legal.tsx" /* webpackChunkName: "component---src-templates-legal-tsx" */)
}

